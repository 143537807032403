import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { boxShadow } from "../basic/box-shadow.css"
import { PostCardSmall } from "../basic/PostCard"
import { colors } from "../colors.css"
import { theme } from "../theme.css"
import {
  Box,
  Container,
  Flex,
  Heading,
  HomepageImage,
  HomepageLink,
  Link,
  Section,
} from "./ui"

export interface HeroProps {
  image?: HomepageImage
  kicker?: string
  h1: string
  subhead: string
  text: string
}

export default function Welcome({ posts }) {
  return (
    <Section>
      <Container>
        <Heading as="h1">Willkommen</Heading>
        <hr style={{ border: `1px solid ${theme.colors.text}` }} />
        <Box
          width="full"
          className={boxShadow}
          style={{ backgroundColor: colors.primary }}
        >
          <Flex gap={0} variant="responsive">
            <Box width="half">
              <StaticImage alt="" src={"../images/TEAM_1.jpg"} />
            </Box>
            <Box width="half">
              <Box padding={2}>
                <PostCardSmall {...posts[0]} />
              </Box>
              <Box padding={2}>
                <PostCardSmall {...posts[1]} />
              </Box>
              <Box padding={2}>
                <PostCardSmall {...posts[2]} />
              </Box>
            </Box>

            {/*<Box width="half" padding={4}>
            <Text style={{ color: theme.colors.long_text }} as="p">
              liebe Mitglieder, Anhänger, Freunde und Follower des FC
              Ottenhöfen.
            </Text>
            <ButtonList links={links} />
        </Box>*/}
          </Flex>
        </Box>
      </Container>
    </Section>
  )
}
