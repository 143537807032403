import * as React from "react"
import {
  Container,
  Box,
  Kicker,
  Heading,
  Section,
  Text,
  Subhead,
  Button,
  Flex,
} from "./ui"
import { FeatureDataProps } from "./feature"
import { boxShadow } from "../basic/box-shadow.css"
import { useState } from "react"
import { theme } from "../theme.css"

export default function Location() {
  React.useEffect(() => {
    const isAccepted = !!window.localStorage.getItem("google-tracking-accepted")
    if (isAccepted) {
      setAccepted(true)
    }
  }, [])

  const [accepted, setAccepted] = useState(false)

  return (
    <Section id="anfahrt">
      <Container>
        <Box width="full" paddingY={4} padding={2} className={boxShadow}>
          <Heading center>
            <Kicker>Anfahrt</Kicker>
          </Heading>
          {!accepted && <Subhead>Datenschutz</Subhead>}
          {!accepted && (
            <Text style={{ color: theme.colors.long_text }} as="p">
              Diese Webseite verwendet Google Maps um Kartenmaterial
              einzubinden. Bitte beachten Sie dass hierbei Ihre persönlichen
              Daten erfasst und gesammelt werden können. Um die Google Maps
              Karte zu sehen, stimmen Sie bitte zu, dass diese vom Google-Server
              geladen wird. Weitere Informationen finden sie{" "}
              <a href="/datenschutz">HIER</a>.
            </Text>
          )}
          {!accepted && (
            <Flex alignItems="center">
              <Button
                href="#anfahrt"
                onClick={() => {
                  window.localStorage.setItem("google-tracking-accepted", "X")
                  setAccepted(true)
                }}
              >
                Akzeptieren
              </Button>
            </Flex>
          )}
          {accepted && (
            <iframe
              style={{ border: "0" }}
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2830.462039268843!2d8.15212020993464!3d48.562467899525465!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0:0x2d7414504af3aedb!2sFC+Ottenhöfen!5e0!3m2!1sde!2sde!4v1498990573798"
              width="100%"
              height="450"
              frameBorder="0"
              allowFullScreen={true}
            />
          )}
        </Box>
      </Container>
    </Section>
  )
}

/*export const query = graphql`
  fragment HomepageFeatureListContent on HomepageFeatureList {
    id
    kicker
    heading
    text
    content {
      id
      ...HomepageFeatureContent
    }
  }
`*/
