import * as React from "react"
import { graphql, Script } from "gatsby"
import Head from "../components/head"
import Welcome from "../components/welcome"
import SponsorList from "../components/sponsors"
import Location from "../components/location"
import Contacts from "../components/contact"

export default function Homepage({ data }) {
  const latestPosts = data.allWpPost.edges.map((edge) => edge.node)

  return (
    <>
      <Head title="FC Ottenhöfen" />
      <Welcome posts={latestPosts} />
      <SponsorList />
      <Location />
      <Contacts />
    </>
  )
}

export const query = graphql`
  query {
    allWpPost(
      limit: 3
      sort: { order: [DESC, DESC], fields: [isSticky, date] }
    ) {
      edges {
        node {
          id
          slug
          title
          isSticky
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  }
`
