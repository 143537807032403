import * as React from "react"
import {
  Container,
  Section,
  Text,
  Kicker,
  Heading,
  Box,
  HomepageImage,
  HomepageLink,
  Link,
  Flex,
  FlexList,
} from "./ui"
import { Mail, Phone, Smartphone } from "react-feather"
import { theme } from "../theme.css"
import { boxShadow } from "../basic/box-shadow.css"
import { navlink } from "./ui.css"
import { TabContainer } from "../basic/TabContainer"

interface StatProps {
  id: string
  value: string
  label: string
}

export interface StatListProps {
  icon?: HomepageImage
  kicker?: string
  heading: string
  text?: string
  content: StatProps[]
  links: HomepageLink[]
  image?: HomepageImage
}

const contactsGeneral = [
  {
    name: "Vorstand",
    email: "vorstand@fcottenhoefen.de",
  },
  {
    name: "Spielbetrieb",
    email: "spielbetrieb@fcottenhoefen.de",
  },
  {
    name: "Jugendleitung",
    email: "jugendleiter@fcottenhoefen.de",
  },
  {
    name: "Homepage",
    email: "webmaster@fcottenhoefen.de",
  },
]

const contactsBoard = [
  {
    name: "Jürgen Knapp",
    role: "Vorsitzender Finanzen",
    email: "juergen.knapp@fcottenhoefen.de",
    phone: "+49 07842 8928",
    mobil: "+49 175 4017792",
  },
  {
    name: "Michael Bühler",
    email: "michael.buehler@fcottenhoefen.de",
    role: "Abteilungsleiter Finanzen",
    mobil: "+49 151 21716782",
  },
  {
    name: "Georg Zeferer",
    role: "Vorsitzender Sport",
    email: "georg.zeferer@fcottenhoefen.de",
    phone: "+49 7842 9965883",
    mobil: "+49 176 56533401",
  },
  {
    name: "Manuel Basler",
    role: "Vorsitzender Verwaltung",
    email: "manuel.basler@fcottenhoefen.de",
    mobil: "+49 172 3216482",
  },
  {
    name: "N.N.",
    role: "Koordinator Sport-/Spielbetrieb",
    email: "",
    phone: "",
    mobil: "",
  },
  {
    name: "Kai Huber",
    email: "kai.huber@fcottenhoefen.de",
    role: "Spielausschuß Senioren",
    mobil: "+49 171 2969560",
  },
  {
    name: "Tobias Baßler",
    email: "tobias.bassler@fcottenhoefen.de",
    role: "Jugendleiter",
    mobil: "+49 1543 121488",
  },
  {
    name: "Daniel Roth",
    email: "daniel.roth@fcottenhoefen.de",
    role: "stellv. Jugendleiter",
    mobil: "+49 176 70699962",
  },
  {
    name: "Dominik Schneider",
    email: "dominik.schneider@fcottenhoefen.de",
    role: "Abteilungsleiter Sponsoring, Marketing",
    mobil: "+49 176 10545385",
  },
  {
    name: "N.N",
    email: "",
    role: "Abteilungsleiter Öffentlichkeitsarbeit",
    mobil: "",
  },
  {
    name: "Rico Schemel",
    email: "rico.schemel@fcottenhoefen.de",
    role: "Abteilungsleiter Freizeitsport",
    mobil: "+49 176 32263165",
  },
  {
    name: "Huber Dirk",
    role: "Elternvertreter",
    phone: "+49 7842 995429",
    mobil: "+49 176 20191482",
  },
  {
    name: "Elena Zeferer",
    role: "Damen- und Mädchenbeauftragte",
    mobil: "+49 151 40174874",
    email: "elena.zeferer@fcottenhoefen.de",
  },
  {
    name: "Frank Decker",
    role: "Veranstaltungen",
    email: "frank.decker@fcottenhoefen.de",
    mobil: "+49 176 31257120",
  },
  {
    name: "Nikolas Basler",
    role: "Bauwesen",
    email: "nikolas.basler@fcottenhoefen.de",
    mobil: "+49 172 6571929",
  },
]

const contactsCoach = [
  {
    name: "Pascal Niebel",
    role: "1. Mannschaft",
    mobil: "+49 151 52460276",
  },
  {
    name: "Udo Deuchler",
    role: "2. Mannschaft",
    mobil: "+49 170 2083820",
  },
  {
    name: "Frank Decker",
    role: "3. Mannschaft",
    email: "frank.decker@fcottenhoefen.de",
    mobil: "+49 176 31257120",
  },
  {
    name: "Klaus Baudendistel",
    role: "Torwarttrainer",
    phone: "+49 152 23072603",
  },
  {
    name: "Richard Huber",
    email: "richard.huber@fcottenhoefen.de",
    role: "AH-Mannschaft",
    phone: "+49 7842 8588",
  },
]

const Contact = ({ contact }) => {
  return (
    <tr>
      <td style={{ color: theme.colors.long_text, width: "200px" }}>
        <Text style={{ marginBottom: "8px", marginTop: "8px" }}>
          {contact.name}
        </Text>
      </td>
      {contact.role && (
        <td style={{ color: theme.colors.long_text, width: "300px" }}>
          <Text style={{ marginBottom: "8px", marginTop: "8px" }}>
            {contact.role}
          </Text>
        </td>
      )}
      <td style={{ width: "300px" }}>
        <Flex
          wrap={true}
          responsive
          alignItems="center"
          style={{ justifyContent: "flex-end" }}
        >
          <Flex gap={1} style={{ width: "87px" }}>
            {contact.email && <Mail></Mail>}
            {contact.email && (
              <Link href={`mailto:${contact.email}`}>E-Mail</Link>
            )}
          </Flex>
          <Flex gap={1} style={{ width: "87px" }}>
            {contact.phone && <Phone></Phone>}
            {contact.phone && (
              <Link href={`tel:${contact.phone}`}>Telefon</Link>
            )}
          </Flex>

          <Flex gap={1} style={{ width: "87px" }}>
            {contact.mobil && <Smartphone></Smartphone>}
            {contact.mobil && <Link href={`tel:${contact.mobil}`}>Handy</Link>}
          </Flex>
        </Flex>
      </td>
    </tr>
  )
}

const TabContent = (props: { contacts: any }) => {
  const { contacts } = props

  return (
    <Flex alignItems="center">
      <table>
        {contacts.map((contact) => {
          return <Contact contact={contact} />
        })}
      </table>
    </Flex>
  )
}

export default function Contacts() {
  return (
    <Section id="kontakt">
      <Container>
        <Box width="full" paddingY={4} className={boxShadow}>
          <TabContainer
            headingText="Kontakt"
            tabs={[
              {
                text: "Allgemein",
                content: <TabContent contacts={contactsGeneral} />,
              },
              {
                text: "Vorstandschaft",
                content: <TabContent contacts={contactsBoard} />,
              },
              {
                text: "Trainerstab",
                content: <TabContent contacts={contactsCoach} />,
              },
            ]}
          />
        </Box>
      </Container>
    </Section>
  )
}

/*export const query = graphql`
  fragment HomepageStatListContent on HomepageStatList {
    id
    kicker
    heading
    text
    image {
      id
      alt
      gatsbyImageData
    }
    icon {
      id
      alt
      gatsbyImageData
    }
    content {
      id
      value
      label
      heading
    }
    links {
      id
      href
      text
    }
  }
`*/
