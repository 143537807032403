import * as React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Section,
  FlexList,
  Text,
  Kicker,
  Heading,
  Subhead,
  Box,
  HomepageImage,
  Space,
  Flex,
} from "./ui"
import { StaticImage } from "gatsby-plugin-image"
import { boxShadow } from "../basic/box-shadow.css"

interface ProductProps {
  id: string
  image: HomepageImage
  heading: string
  text: string
  list: { image: React.ReactNode; href: string }[]
}

function SponsorRow(props: ProductProps) {
  return (
    <tr>
      <td style={{ backgroundColor: "white" }}>
        <Text variant="bold">{props.heading}</Text>
      </td>
      <td>
        <Flex
          style={{
            justifyContent: "center",
            backgroundColor: "white",
            padding: "4px",
          }}
        >
          {props.list.map((logo, index) => {
            return (
              <a key={index} href={logo.href}>
                {logo.image}
              </a>
            )
          })}
        </Flex>
      </td>
    </tr>
  )
}

export interface ProductListProps {
  kicker?: string
  heading: string
  text?: string
  content: ProductProps[]
}

const imageWidth = 150

const content = [
  {
    id: "0",
    image: null,
    heading: "Premium Sponsoren",
    text: "Badenova, Fischer Edelstahlrohre, Süwag",
    list: [
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-badenova.jpg"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.badenova.de",
      },
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-fischer-group.svg"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.fischer-group.com",
      },
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-suewag.svg"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.suewag.de",
      },
    ],
  },
  {
    id: "1",
    image: null,
    heading: "Hauptsponsoren",
    text: "Huber-Bau, Fischer Edelstahlrohre, Süwag",
    list: [
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-huber-bau.svg"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.huber-bau.de/",
      },
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-sportkern.jpg"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://sport-kern-seebach.de/",
      },
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-sparkasse.jpg"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.sparkasse-offenburg.de/",
      },
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-volksbank.png"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.gestalterbank.de/home.html",
      },
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-weber.png"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.weber-seebach.de/",
      },
    ],
  },
  {
    id: "2",
    image: null,
    heading: "Ausrüstungspartner",
    text: "Bürk Sägewerk",
    list: [
      {
        image: (
          <StaticImage
            src={"../images/sponsoren-buerk.png"}
            width={imageWidth}
            alt=""
          />
        ),
        href: "https://www.saegewerk-buerk.de/",
      },
    ],
  },
]

export default function SponsorList() {
  return (
    <Section>
      <Container>
        <Box center paddingY={4} className={boxShadow}>
          <Heading>
            <Kicker>Sponsoren</Kicker>
          </Heading>
          {/*props.text && <Text>{props.text}</Text>*/}
          <table>
            {content.map((product) => (
              <SponsorRow {...product} />
            ))}
          </table>
        </Box>
      </Container>
    </Section>
  )
}

/*export const query = graphql`
  fragment HomepageProductListContent on HomepageProductList {
    id
    kicker
    heading
    text
    content {
      id
      heading
      text
      image {
        alt
        id
        gatsbyImageData
      }
      links {
        id
        href
        text
      }
    }
  }
`*/
